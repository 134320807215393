@font-face {
  font-family: NocturneSerif;
  src: url("./css//NocturneSerif-Regular.ttf");
}

* {
  font-family: NocturneSerif;
  margin: 0;
  padding: 0;
}
